<template>
  <tr>
    <td class="justify-center">
      <v-btn outline small>
        <v-icon small>format_list_bulleted</v-icon>
        <span> Ver Conteúdo</span>  
      </v-btn>
    </td>
    <td class="justify-left">
      <strong>{{props.item.form.name}}</strong>
    </td>
    <td class="justify-left">
      {{props.item.person.name}}
    </td>
    <td class="justify-left">
      <div style="text-align: center;">
        <v-icon>
          {{props.item.status.icon}}
        </v-icon>
        <br>
        <strong :style="{color: props.item.status.color}">
          {{props.item.status.title}}
        </strong>
      </div>
    </td>
    <td class="justify-left">
      {{ props.item.created_at | moment('DD/MM/YY HH:mm') }}
      <br>
      {{ props.item.updated_at | moment('DD/MM/YY HH:mm') }}
    </td>
  </tr>
</template>
<script>
  export default {
    name: 'FormReplies',
    methods: {
      goToFormItems: function () {
        return {
          name: 'wells-list',
          params: {
            page: 'well-config-form-item'
          },
          query: {
            where: 'form_id,' + this.props.item.id
          }
        }
      }
    },
    props: {
      config: Object,
      props: Object
    }
  }
</script>
<style scoped>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
  .persons__row-inactive {
    background: rgb(209, 209, 209);
  }
  .persons__status {
    border-width: 1px;
    border-style: solid;
    padding: 2px 5px;
  }
  .persons__status--active {
    color: #eceff1;
    background: rgb(4, 112, 0);
  }
  .persons__status--inactive {
    color: grey;
    background: #eceff1;
  }
</style>